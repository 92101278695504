import React, { useState } from "react";

const Home4 = (props) => {
  const [carCount, setcarCount] = useState({ count: "" });
  function handleChange(e) {
    setcarCount({
      [e.target.name]: e.target.value,
    });
  }

  console.log(carCount.count);
  return (
    <div class="home 4">
      {" "}
      <div class="hero mt-14 items-center pb-10 md:mt-20 items-center pb-10 px-4 md:px-0">
        <div class="mx-auto max-w-7xl">
          <div class="md:flex justify-between items-start">
            <div class="md:w-1/2">
              <nav class="opacity-100 flex" aria-p="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div>
                      <a href="/" class="text-white hover:text-gray-200">
                        <svg
                          class="flex-shrink-0 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span class="sr-only">Home</span>
                      </a>
                    </div>
                  </li>

                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Home1";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        Residential
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Home2";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        House Info
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Home3";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        Energy
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button class="pointer-events-none ml-4 text-sm font-medium text-white hover:text-gray-200">
                        Transportation
                      </button>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                Residential
              </h1>
              <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                How your household members get around contributes to your
                household emissions.
              </p>{" "}
              <div class="energy options mt-5 border border-blue-200 bg-blue-100 pt-6 pb-6 px-4 rounded-lg">
                <h2 class="text-base font-medium md:text-lg text-gray-600">
                  How many vehicles do you own?
                </h2>
                <select
                  name="count"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  class="mt-8 block w-full pl-3 text-lg text-gray-600 py-4 border border-blue-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500  rounded-md"
                >
                  <option value="0" default selected>
                    None
                  </option>

                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
              {carCount.count == "1" ||
              carCount.count == "2" ||
              carCount.count == "3" ||
              carCount.count == "4" ? (
                <div class="w-full car1 mt-8 pb-4 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M21.739 10.921c-1.347-.39-1.885-.538-3.552-.921 0 0-2.379-2.359-2.832-2.816-.568-.572-1.043-1.184-2.949-1.184h-7.894c-.511 0-.736.547-.07 1-.742.602-1.619 1.38-2.258 2.027-1.435 1.455-2.184 2.385-2.184 4.255 0 1.76 1.042 3.718 3.174 3.718h.01c.413 1.162 1.512 2 2.816 2 1.304 0 2.403-.838 2.816-2h6.367c.413 1.162 1.512 2 2.816 2s2.403-.838 2.816-2h.685c1.994 0 2.5-1.776 2.5-3.165 0-2.041-1.123-2.584-2.261-2.914zm-15.739 6.279c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2zm3.576-6.2c-1.071 0-3.5-.106-5.219-.75.578-.75.998-1.222 1.27-1.536.318-.368.873-.714 1.561-.714h2.388v3zm1-3h1.835c.882 0 1.428.493 2.022 1.105.452.466 1.732 1.895 1.732 1.895h-5.588v-3zm7.424 9.2c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2z" />
                      </svg>
                      <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                        Vehicle #1
                      </h1>
                    </div>

                    {props.data.firstCarFuel === "Electric" ? (
                      <div class="car 1">
                        <div class="mt-6 relative rounded-md">
                          <select
                            name="firstCarFuel"
                            onChange={props.addData}
                            class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                          >
                            <option value="empty" default selected>
                              Fuel Type
                            </option>
                            <option value="Gas">Gasoline/Hybrid</option>

                            <option value="Diesel">Diesel</option>
                            <option value="Electric">Electric</option>
                          </select>
                        </div>
                        <div class="mt-4 opacity-50 relative rounded-md">
                          <input
                            type="number"
                            name="firstCarMpg"
                            disabled
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated Miles/Gallon"
                          ></input>
                        </div>
                        <div class="mt-4 opacity-50 relative rounded-md">
                          <input
                            type="number"
                            name="firstCarMpy"
                            disabled
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated miles driven/year"
                          ></input>
                        </div>
                      </div>
                    ) : (
                      <div class="car 1">
                        <div class="mt-6 relative rounded-md">
                          <select
                            name="firstCarFuel"
                            onChange={props.addData}
                            class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                          >
                            <option value="empty" default selected>
                              Fuel Type
                            </option>
                            <option value="Gas">Gasoline/Hybrid</option>

                            <option value="Diesel">Diesel</option>
                            <option value="Electric">Electric</option>
                          </select>
                        </div>
                        <div class="mt-4 relative rounded-md">
                          <input
                            type="number"
                            name="firstCarMpg"
                            onChange={props.addData}
                            onWheel={(e) => e.target.blur()}
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated Miles/Gallon"
                          ></input>
                        </div>
                        <div class="mt-4 relative rounded-md">
                          <input
                            type="number"
                            name="firstCarMpy"
                            onChange={props.addData}
                            onWheel={(e) => e.target.blur()}
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated miles driven/year"
                          ></input>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {carCount.count == "2" ||
              carCount.count == "3" ||
              carCount.count == "4" ? (
                <div class="w-full car2 mt-8 pb-4 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M21.739 10.921c-1.347-.39-1.885-.538-3.552-.921 0 0-2.379-2.359-2.832-2.816-.568-.572-1.043-1.184-2.949-1.184h-7.894c-.511 0-.736.547-.07 1-.742.602-1.619 1.38-2.258 2.027-1.435 1.455-2.184 2.385-2.184 4.255 0 1.76 1.042 3.718 3.174 3.718h.01c.413 1.162 1.512 2 2.816 2 1.304 0 2.403-.838 2.816-2h6.367c.413 1.162 1.512 2 2.816 2s2.403-.838 2.816-2h.685c1.994 0 2.5-1.776 2.5-3.165 0-2.041-1.123-2.584-2.261-2.914zm-15.739 6.279c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2zm3.576-6.2c-1.071 0-3.5-.106-5.219-.75.578-.75.998-1.222 1.27-1.536.318-.368.873-.714 1.561-.714h2.388v3zm1-3h1.835c.882 0 1.428.493 2.022 1.105.452.466 1.732 1.895 1.732 1.895h-5.588v-3zm7.424 9.2c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2z" />
                      </svg>
                      <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                        Vehicle #2
                      </h1>
                    </div>

                    {props.data.secondCarFuel === "Electric" ? (
                      <div class="car 1">
                        <div class="mt-6 relative rounded-md">
                          <select
                            name="secondCarFuel"
                            onChange={props.addData}
                            class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                          >
                            <option value="empty" default selected>
                              Fuel Type
                            </option>
                            <option value="Gas">Gasoline/Hybrid</option>

                            <option value="Diesel">Diesel</option>
                            <option value="Electric">Electric</option>
                          </select>
                        </div>
                        <div class="mt-4 opacity-50 relative rounded-md">
                          <input
                            type="number"
                            name="secondCarMpg"
                            disabled
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated Miles/Gallon"
                          ></input>
                        </div>
                        <div class="mt-4 opacity-50 relative rounded-md">
                          <input
                            type="number"
                            name="secondCarMpy"
                            disabled
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated miles driven/year"
                          ></input>
                        </div>
                      </div>
                    ) : (
                      <div class="car 2">
                        <div class="mt-6 relative rounded-md">
                          <select
                            name="secondCarFuel"
                            onChange={props.addData}
                            class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                          >
                            <option value="empty" default selected>
                              Fuel Type
                            </option>
                            <option value="Gas">Gasoline/Hybrid</option>

                            <option value="Diesel">Diesel</option>
                            <option value="Electric">Electric</option>
                          </select>
                        </div>
                        <div class="mt-4 relative rounded-md">
                          <input
                            type="number"
                            name="secondCarMpg"
                            onChange={props.addData}
                            onWheel={(e) => e.target.blur()}
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated Miles/Gallon"
                          ></input>
                        </div>
                        <div class="mt-4 relative rounded-md">
                          <input
                            type="number"
                            name="secondCarMpy"
                            onChange={props.addData}
                            onWheel={(e) => e.target.blur()}
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated miles driven/year"
                          ></input>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {carCount.count == "3" || carCount.count == "4" ? (
                <div class="w-full car3 mt-8 pb-4 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M21.739 10.921c-1.347-.39-1.885-.538-3.552-.921 0 0-2.379-2.359-2.832-2.816-.568-.572-1.043-1.184-2.949-1.184h-7.894c-.511 0-.736.547-.07 1-.742.602-1.619 1.38-2.258 2.027-1.435 1.455-2.184 2.385-2.184 4.255 0 1.76 1.042 3.718 3.174 3.718h.01c.413 1.162 1.512 2 2.816 2 1.304 0 2.403-.838 2.816-2h6.367c.413 1.162 1.512 2 2.816 2s2.403-.838 2.816-2h.685c1.994 0 2.5-1.776 2.5-3.165 0-2.041-1.123-2.584-2.261-2.914zm-15.739 6.279c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2zm3.576-6.2c-1.071 0-3.5-.106-5.219-.75.578-.75.998-1.222 1.27-1.536.318-.368.873-.714 1.561-.714h2.388v3zm1-3h1.835c.882 0 1.428.493 2.022 1.105.452.466 1.732 1.895 1.732 1.895h-5.588v-3zm7.424 9.2c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2z" />
                      </svg>
                      <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                        Vehicle #3
                      </h1>
                    </div>

                    {props.data.thirdCarFuel === "Electric" ? (
                      <div class="car 1">
                        <div class="mt-6 relative rounded-md">
                          <select
                            name="thirdCarFuel"
                            onChange={props.addData}
                            class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                          >
                            <option value="empty" default selected>
                              Fuel Type
                            </option>
                            <option value="Gas">Gasoline/Hybrid</option>

                            <option value="Diesel">Diesel</option>
                            <option value="Electric">Electric</option>
                          </select>
                        </div>
                        <div class="mt-4 opacity-50 relative rounded-md">
                          <input
                            type="number"
                            name="thirdCarMpg"
                            disabled
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated Miles/Gallon"
                          ></input>
                        </div>
                        <div class="mt-4 opacity-50 relative rounded-md">
                          <input
                            type="number"
                            name="thirdCarMpy"
                            disabled
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated miles driven/year"
                          ></input>
                        </div>
                      </div>
                    ) : (
                      <div class="car 3">
                        <div class="mt-6 relative rounded-md">
                          <select
                            name="thirdCarFuel"
                            onChange={props.addData}
                            class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                          >
                            <option value="empty" default selected>
                              Fuel Type
                            </option>
                            <option value="Gas">Gasoline/Hybrid</option>

                            <option value="Diesel">Diesel</option>
                            <option value="Electric">Electric</option>
                          </select>
                        </div>
                        <div class="mt-4 relative rounded-md">
                          <input
                            type="number"
                            name="thirdCarMpg"
                            onChange={props.addData}
                            onWheel={(e) => e.target.blur()}
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated Miles/Gallon"
                          ></input>
                        </div>
                        <div class="mt-4 relative rounded-md">
                          <input
                            type="number"
                            name="thirdCarMpy"
                            onChange={props.addData}
                            onWheel={(e) => e.target.blur()}
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated miles driven/year"
                          ></input>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {carCount.count == "4" ? (
                <div class="w-full car4 mt-8 pb-4 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M21.739 10.921c-1.347-.39-1.885-.538-3.552-.921 0 0-2.379-2.359-2.832-2.816-.568-.572-1.043-1.184-2.949-1.184h-7.894c-.511 0-.736.547-.07 1-.742.602-1.619 1.38-2.258 2.027-1.435 1.455-2.184 2.385-2.184 4.255 0 1.76 1.042 3.718 3.174 3.718h.01c.413 1.162 1.512 2 2.816 2 1.304 0 2.403-.838 2.816-2h6.367c.413 1.162 1.512 2 2.816 2s2.403-.838 2.816-2h.685c1.994 0 2.5-1.776 2.5-3.165 0-2.041-1.123-2.584-2.261-2.914zm-15.739 6.279c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2zm3.576-6.2c-1.071 0-3.5-.106-5.219-.75.578-.75.998-1.222 1.27-1.536.318-.368.873-.714 1.561-.714h2.388v3zm1-3h1.835c.882 0 1.428.493 2.022 1.105.452.466 1.732 1.895 1.732 1.895h-5.588v-3zm7.424 9.2c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2z" />
                      </svg>
                      <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                        Vehicle #4
                      </h1>
                    </div>

                    {props.data.fourthCarFuel === "Electric" ? (
                      <div class="car 1">
                        <div class="mt-6 relative rounded-md">
                          <select
                            name="fourthCarFuel"
                            onChange={props.addData}
                            class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                          >
                            <option value="empty" default selected>
                              Fuel Type
                            </option>
                            <option value="Gas">Gasoline/Hybrid</option>

                            <option value="Diesel">Diesel</option>
                            <option value="Electric">Electric</option>
                          </select>
                        </div>
                        <div class="mt-4 opacity-50 relative rounded-md">
                          <input
                            type="number"
                            name="fourthCarMpg"
                            disabled
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated Miles/Gallon"
                          ></input>
                        </div>
                        <div class="mt-4 opacity-50 relative rounded-md">
                          <input
                            type="number"
                            name="fourthCarMpy"
                            disabled
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated miles driven/year"
                          ></input>
                        </div>
                      </div>
                    ) : (
                      <div class="car 4">
                        <div class="mt-6 relative rounded-md">
                          <select
                            name="fourthCarFuel"
                            onChange={props.addData}
                            class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                          >
                            <option value="empty" default selected>
                              Fuel Type
                            </option>
                            <option value="Gas">Gasoline/Hybrid</option>

                            <option value="Diesel">Diesel</option>
                            <option value="Electric">Electric</option>
                          </select>
                        </div>
                        <div class="mt-4 relative rounded-md">
                          <input
                            type="number"
                            name="fourthCarMpg"
                            onChange={props.addData}
                            onWheel={(e) => e.target.blur()}
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated Miles/Gallon"
                          ></input>
                        </div>
                        <div class="mt-4 relative rounded-md">
                          <input
                            type="number"
                            name="fourthCarMpy"
                            onChange={props.addData}
                            onWheel={(e) => e.target.blur()}
                            class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-200 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            placeholder="Estimated miles driven/year"
                          ></input>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div class="button-group pt-8 mt-8 md:flex justify-start ">
                <button
                  onClick={() => {
                    const newPosition = "Home3";
                    props.onChange(newPosition);
                  }}
                  class="w-full md:w-1/2 rounded-md flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white bg-gradient-to-r from-fadedGreen to-neonGreen hover:opacity-90 md:py-4 md:text-lg md:px-10 "
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    const newPosition = "PreCheckout";
                    props.onChange(newPosition);
                  }}
                  class="w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white bg-neonGreen hover:opacity-90 md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3"
                >
                  Complete
                </button>
              </div>
            </div>
            <form class="checkout mt-5 md:pl-10 md:w-2/5 md:sticky top-5 ">
              <section class="mt-16 bg-gray-50 border border-emerald-500 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
                {props.data.uetGreen === "true" ? (
                  <span class="mb-5 flex justify-center w-1/2 mx-auto py-1 rounded-full text-xs font-medium bg-emerald-100 text-green-800">
                    {" "}
                    <svg
                      class="mr-2 h-5 text-green-800"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h4 class=" text-sm font-medium text-green-800 mb-0">
                      Green Gas Customer
                    </h4>
                  </span>
                ) : (
                  <div></div>
                )}
                <div class="flex justify-center">
                  {" "}
                  <svg
                    class="h-7 md:h-8 text-emerald-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 23h-3v-5.486c-1.827-.518-3-2.24-3-3.988 0-.585.129-1.172.407-1.716-.323-1.661.657-3.344 2.356-3.881l.018-.005c.773-1.102 2.057-1.826 3.535-1.898l5.666-5.026 12.018 10.661-1.33 1.493-10.688-9.482-3.274 2.905c.305.162.591.358.849.589l2.415-2.142 9.026 7.989v9.987h-2.998v-7h-5l-.008 7h-5.992v-6.061c.546-.125 1.034-.463 1.317-1.044 2.096.786 3.746-2.273 1.82-3.562 1.073-1.237.188-2.747-1.208-2.525-.673-2.384-4.178-2.384-4.851 0-1.31-.235-2.295 1.094-1.385 2.291-1.654 1.38-.162 4.084 1.872 3.473.214.74.794 1.2 1.435 1.362v6.066zm.451-10.678c1.493-1.023 3.242.768 2.303 2.226.002-1.473-.853-2.282-2.303-2.226m-2.119 1.191c-.668-1.002-.34-2.366.705-2.968.589-.338 1.33-.369 1.953-.07 1.06.507-2.743-.678-2.658 3.038"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <h2 class="text-xl mt-1 ml-2 md:text-2xl text-emerald-600">
                    Carbon Footprint
                  </h2>
                </div>

                <dl class="mt-3 space-y-4">
                  <p class="text-center mx-auto pb-4 px-4 text-base md:text-lg text-gray-500">
                    Your purchase will eliminate{" "}
                    <span class="text-emerald-600 font-bold ">
                      {" "}
                      {props.usage} TONS
                    </span>{" "}
                    of greenhouse gases Annually
                  </p>
                  <div class="border-t border-gray-200 pt-8 flex items-center justify-center">
                    <fieldset class="">
                      {props.data.priceType === "yearly" ? (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            <input
                              value="monthly"
                              name="priceType"
                              type="radio"
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />

                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${(props.priceYear / 12).toFixed(2)}
                              <span class="text-base font-medium">/mo</span>
                            </p>
                          </div>

                          <div class="flex items-center">
                            <input
                              value="yearly"
                              name="priceType"
                              type="radio"
                              checked
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${props.priceYear.toFixed(2)}
                              <span class="text-base font-medium">/yr</span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            <input
                              value="monthly"
                              name="priceType"
                              type="radio"
                              checked
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />

                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${(props.priceYear / 12).toFixed(2)}
                              <span class="text-base font-medium">/mo</span>
                            </p>
                          </div>

                          <div class="flex items-center">
                            <input
                              value="yearly"
                              name="priceType"
                              type="radio"
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${props.priceYear.toFixed(2)}
                              <span class="text-base font-medium">/yr</span>
                            </p>
                          </div>
                        </div>
                      )}
                    </fieldset>
                  </div>{" "}
                </dl>

                {props.usage === 0 ? (
                  <div class="mt-7">
                    <button
                      type="button"
                      class="w-full pointer-events-none opacity-50 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                ) : (
                  <div class="mt-7">
                    <button
                      onClick={() => {
                        const newPosition = "PreCheckout";
                        props.onChange(newPosition);
                      }}
                      type="button"
                      class="w-full py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                )}
              </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home4;
